/* .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
}

.main-title {
    display: flex;
    justify-content: space-between;
}

.main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
} */


/* 
*/


/* .main-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 15px 0;
    /* justify-content: space-between; */


/* .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
}


.card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-inner>.card_icon {
    font-size: 25px;
}

.charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
}

@media (min-width: 768px) {
    .card {
        width: 63%;
    }
} */

.main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px;
    margin: 15px 0;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    height: 400px;
    width: 50px;
}